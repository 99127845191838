export function lastOf(data) {
    return data[data.length - 1];
}

function getKeyOrIndex(obj, key, index) {
    return obj[Array.isArray(obj) ? index : key]
}

const DI_INDEXES = {
    "ts": 0,
    "lat": 1,
    "lon": 2,
    "speedMps": 3,
    "bearingRad": 4,
    "distanceToPointM": 5,
}

export function getDriverInfoField(obj, key) {
    return getKeyOrIndex(obj, key, DI_INDEXES[key]);
}

const BI_INDEXES = {
    "ts": 0,
    "amplitude": 1,
    "isActivated": 2,
}

export function getBeepingInfoField(obj, key) {
    return getKeyOrIndex(obj, key, BI_INDEXES[key]);
}

