import React from "react";
import sha1 from "js-sha1";

import EditorMarker from "./marker";
import TimeSelectorOverlay from "./overlay";
import colormap from "colormap";
import {lastOf, getDriverInfoField, getBeepingInfoField} from "utils";

const imgCache = new Map();

const IMAGE_SIZE = 512;

const CMAP = colormap({
    colormap: 'jet',
    nshades: 1001,
    format: 'rgba',
    alpha: 1
});

function genImages(colors, height) {
    const dataKey = sha1(JSON.stringify([colors, height]));
    const cacheResponse = imgCache.get(dataKey);
    if (cacheResponse != null) {
        return cacheResponse;
    }
    const result = [];
    for (let offset = 0; offset < colors.length; offset += IMAGE_SIZE) {
        const width = Math.min(IMAGE_SIZE, colors.length - offset);
        const buffer = new Uint8ClampedArray(width * height * 4);
        for(let y = 0; y < height; y++) {
            for(var x = 0; x < width; x++) {
                const pos = (y * width + x) * 4;
                const color = colors[offset + x];
                buffer[pos] = color[0];
                buffer[pos + 1] = color[1];
                buffer[pos + 2] = color[2];
                buffer[pos + 3] = 255;
            }
        }
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        const idata = ctx.createImageData(width, height);
        idata.data.set(buffer);
        ctx.putImageData(idata, 0, 0);
        result.push(canvas.toDataURL());
    }
    imgCache.set(dataKey, result);
    return result;
}

export default class RunEditor extends React.Component {
    state = {
        zoom: 250,
    }

    componentDidUpdate() {
        if (this._scrollPct != null) {
            this._setScrollPct(this._scrollPct);
            this._scrollPct = null;
        }
    }

    _scrollPct = null

    _getScrollPct() {
        if (this._timelineComponent == null) {
            console.log("Root component null (get)");
            return 0.0;
        }
        const {scrollLeft, scrollWidth, clientWidth} = this._timelineComponent;
        return (0.5 * clientWidth + scrollLeft) / scrollWidth;
    }
    _setScrollPct(value) {
        if (this._timelineComponent == null) {
            console.log("Root component null (set)");
            return;
        }
        const {scrollWidth, clientWidth} = this._timelineComponent;
        this._timelineComponent.scrollLeft = Math.min(Math.max(
            value * scrollWidth - 0.5 * clientWidth,
        0), scrollWidth - clientWidth);
    }

    _doMath() {
        const data = this.props.runData.getKeyData(this.props.activeKey);
        const pData = this.props.projectData[this.props.activeKey];
        const positionTimes = data.driverInfos.map(di => getDriverInfoField(di, "ts"));
        if (positionTimes.length === 0) {
            return null;
        }
        const timeStart = positionTimes[0];
        const timeEnd = lastOf(positionTimes);
        const durationMs = timeEnd - timeStart;
        if (durationMs <= 0) {
            return null;
        }
        const pxSize = this.state.zoom;
        const lengthPx = durationMs / pxSize;
        const markers = pData.markers.map(marker => ({
            "offsetPx": (marker.ts - timeStart) / pxSize,
            ...marker,
        }));
        return {
            timeStart,
            timeEnd,
            durationMs,
            lengthPx,
            pxSize,
            markers,
        }
    }

    _handleMarkerChange = (idx, update) => {
        const pData = this.props.projectData[this.props.activeKey];
        let allMarkers = pData.markers.slice();
        allMarkers[idx] = {...allMarkers[idx], ...update};
        const nextPdata = {...pData, markers: allMarkers};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _handleMarkerDelete = (idx) => {
        const pData = this.props.projectData[this.props.activeKey];
        let allMarkers = pData.markers.slice();
        allMarkers.splice(idx, 1);
        const nextPdata = {...pData, markers: allMarkers};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _handleMarkerCreate = (data) => {
        const pData = this.props.projectData[this.props.activeKey];
        let allMarkers = pData.markers.slice();
        allMarkers.push(data);
        const nextPdata = {...pData, markers: allMarkers};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _handleReset = () => {
        if (!confirm("Are you sure want to reset everything in this tab?")) {
            return;
        }
        this.props.onProjectDataChange({
            ...this.props.projectData,
            [this.props.activeKey]: this.props.runData.makeInitialProjectKeyData(this.props.activeKey),
        });
    }

    _handleRename = () => {
        const pData = this.props.projectData[this.props.activeKey];
        const nextText = prompt("Enter the new name", pData.alias);
        if (nextText == null || nextText.trim() === "") {
            return;
        }
        const nextPdata = {...pData, alias: nextText.trim()};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _setSoundScaleVisibleValue(key, nextChecked) {
        const pData = this.props.projectData[this.props.activeKey];
        const maxValue = pData.sounds[key]?.maxValue ?? 10;
        const nextSounds = {...pData.sounds, [key]: {visible: nextChecked, maxValue}};
        const nextPdata = {...pData, sounds: nextSounds};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _setSoundScaleMaxValue(key, nextValue) {
        const pData = this.props.projectData[this.props.activeKey];
        const visible = pData.sounds[key]?.visible ?? false;
        const nextSounds = {...pData.sounds, [key]: {visible, maxValue: nextValue}};
        const nextPdata = {...pData, sounds: nextSounds};
        this.props.onProjectDataChange({...this.props.projectData, [this.props.activeKey]: nextPdata});
    }

    _handleZoomIn = () => {
        this._scrollPct = this._getScrollPct();
        this.setState(s => ({zoom: s.zoom / 1.5}));
    }
    _handleZoomOut = () => {
        this._scrollPct = this._getScrollPct();
        this.setState(s => ({zoom: s.zoom * 1.5}));
    }

    renderMarkers(m) {
        return m.markers.map((marker, idx) => (
            <EditorMarker
                key={ idx }
                onChange={ update => this._handleMarkerChange(idx, update) }
                onDelete={ this._handleMarkerDelete }
                {...marker}
            />
        ));
    }

    renderSoundScale(m, key, maxValue) {
        const rData = this.props.runData.getKeyData(key);
        const pData = this.props.projectData[key];
        const beeps = rData.beepingInfos
            .filter(b => m.timeStart <= getBeepingInfoField(b, "ts") && getBeepingInfoField(b, "ts") <= m.timeEnd)
            .sort((a, b) => getBeepingInfoField(a, "ts") - getBeepingInfoField(b, "ts"));
        const gradStopsData = new Map();
        for (const b of beeps) {
            let normValue = Math.min(getBeepingInfoField(b, "amplitude") / maxValue, 1.0);
            let offsetPx = Math.round((getBeepingInfoField(b, "ts") - m.timeStart) / m.pxSize);
            let value = Math.max(normValue, gradStopsData.get(offsetPx) ?? 0);
            gradStopsData.set(offsetPx, value);
        }
        const pxValues = [];
        let latestValue = 0;
        for (let i = 0; i < m.lengthPx; ++i) {
            latestValue = gradStopsData.get(i) ?? latestValue;
            const color = CMAP[Math.round(1000.0 * latestValue)];
            pxValues.push(color);
        }
        const imgData = genImages(pxValues, 20);
        return (
            <div className="sound-scale-group" key={key}>
                <h4>
                    { pData.alias }
                </h4>
                { imgData.map((src, idx) => <img className="sound-scale" src={ src } key={ idx } />) }
            </div>
        );
    }

    renderScales() {
        const m = this._doMath();
        if (m == null) {
            return (
                <div className="invalid-data">
                    This run has invalid data.
                    This could happen due to missing GPS positions or due
                    to very short run (less than 0.5 seconds)
                </div>
            );
        }
        const pData = this.props.projectData[this.props.activeKey];
        const soundScales = this.props.runData.getAllKeys(this.props.projectData)
            .filter(key => pData.sounds[key]?.visible);
        return (
            <div className="timeline" ref={ r => this._timelineComponent = r }>
                <div className="markers">
                    { this.renderMarkers(m) }
                </div>
                <div className="scales">
                    <div className="primary-scale"
                        style={{
                            "width": `${m.lengthPx}px`,
                        }}
                    />
                    { soundScales.map(key => this.renderSoundScale(m, key, pData.sounds[key].maxValue)) }
                    <TimeSelectorOverlay
                        onChange={ this._handleMarkerChange }
                        onCreate={ this._handleMarkerCreate }
                        activeKey={ this.props.activeKey }
                        m={ m }
                        runData={ this.props.runData }
                        markers={ pData.markers }
                    />
                </div>
            </div>
        );
    }

    renderSoundScalesRows = (key) => {
        const pData = this.props.projectData[this.props.activeKey];
        const checked = pData.sounds[key]?.visible ?? false;
        const maxValue = pData.sounds[key]?.maxValue ?? 10;
        return (
            <tr key={key}>
                <td>
                    <label className="sound-scale-label">
                        <input
                            type="checkbox"
                            checked={ checked }
                            onChange={ (e) => this._setSoundScaleVisibleValue(key, e.target.checked) }
                        />
                        &nbsp;&nbsp;&nbsp;
                        { this.props.projectData[key].alias }
                    </label>
                </td>
                <td>
                    <input
                        type="range"
                        min="1"
                        max="100"
                        value={ maxValue }
                        className="sound-threshold-slider"
                        onChange={ (e) => this._setSoundScaleMaxValue(key, e.target.value) }
                    />
                    &nbsp;&nbsp;&nbsp;
                    <span className="sound-threshold-slider">
                        { `(threshold: ${maxValue})` }
                    </span>
                </td>
            </tr>
        );
    }

    renderSoundScalesSelection() {
        return (
            <div className="sounds-selector">
                <h3>Configure sounds to display</h3>
                <table>
                    <tbody>
                        { this.props.runData
                            .getAllKeys(this.props.projectData)
                            .filter(key => this.props.runData.checkBoundsOverlap(key, this.props.activeKey))
                            .map(this.renderSoundScalesRows) }
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        if (this.props.activeKey == null) {
            return (
                <div className="run-editor">
                    Nothing selected
                </div>
            );
        }
        return (
            <div className="run-editor">
                <h1 onClick={ this._handleRename }>
                    { this.props.projectData[this.props.activeKey].alias }&nbsp;
                    <small>(click to rename)</small>
                </h1>
                <h2>
                    { this.props.activeKey }
                </h2>
                { this.renderScales() }
                <div className="control-bar">
                    <div>
                        <button onClick={ this._handleZoomIn } disabled={ this.state.zoom <= 5 }>Zoom in</button>
                        <button onClick={ this._handleZoomOut } disabled={ this.state.zoom >= 5000 }>Zoom out</button>
                    </div>
                    <div>
                        <button className="danger" onClick={ this._handleReset }>Reset everything</button>
                    </div>
                </div>
                { this.renderSoundScalesSelection() }
            </div>
        );
    }
}
