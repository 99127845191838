import {lastOf, getDriverInfoField, getBeepingInfoField} from "utils";

export default class RunDataFile {
    constructor(hashsum, data) {
        this.hashsum = hashsum
        this.data = data
        this.markersBounds = Object.fromEntries(Object.keys(data).map(key => {
            const tss = data[key].beepingInfos.map(bi => getBeepingInfoField(bi, "ts"));
            if (tss.length === 0) {
                return [key, [0, 0]];
            }
            return [
                key,
                [tss[0], lastOf(tss)],
            ];
        } ) );
    }

    getAllKeys(projectData) {
        return Object.keys(this.data).sort((a, b) => projectData[a].alias.localeCompare(projectData[b].alias))
    }
    getKeyData(key) {
        return this.data[key];
    }

    makeInitialProjectKeyData(key) {
        const activation = this.data[key].activationInfo;
        return {
            "markers": activation == null ? [] : [
                {
                    "ts": activation.ts,
                    "position": activation.position,
                    "distance": activation.distanceM || 0.0,
                    "label": "First activation",
                }
            ],
            "sounds": {
                [key]: {
                    maxValue: 10.0,
                    visible: true,
                },
            },
            "alias": key,
        };
    }

    _makeInitialProjectData() {
        const result = {};
        for (const key of Object.keys(this.data)) {
            result[key] = this.makeInitialProjectKeyData(key);
        }
        return result;
    }

    getInitialState() {
        let item = window.localStorage.getItem(`workset_${this.hashsum}`);
        let projectData = item == null
            ? this._makeInitialProjectData()
            : JSON.parse(item);
        return {
            "runData": this,
            "projectData": projectData,
        };
    }

    getDistanceAndPositionFromTime(key, ts) {
        const {driverInfos} = this.getKeyData(key);
        let left = 0;
        let right = driverInfos.length;
        while (left < right - 1) {
            const mid = Math.floor((left + right) / 2);
            if (getDriverInfoField(driverInfos[mid], "ts") > ts) {
                right = mid;
            } else {
                left = mid;
            }
        }
        if (left + 1 >= driverInfos.length) {
            return [
                getDriverInfoField(driverInfos[left], "distanceToPointM"),
                getDriverInfoField(driverInfos[left], "position"),
            ];
        }
        const prev = driverInfos[left];
        const next = driverInfos[left + 1];
        const prevTs = getDriverInfoField(prev, "ts");
        const nextTs = getDriverInfoField(next, "ts");
        const frac = (ts - prevTs) / (nextTs - prevTs);
        const blend = f => f(prev) + (f(next) - f(prev)) * frac;
        const distance = blend(x => getDriverInfoField(x, "distanceToPointM"));
        const lat = blend(x => getDriverInfoField(x, "lat"));
        const lon = blend(x => getDriverInfoField(x, "lon"));
        return [distance, {lat, lon}];
    }

    checkBoundsOverlap(key1, key2) {
        const [f1, t1] = this.markersBounds[key1];
        const [f2, t2] = this.markersBounds[key2];
        return !((t1 < f2) || (t2 < f1))
    }
}