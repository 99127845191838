import React from "react";


export default class RunSelector extends React.Component {
    _importProject = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = ".json";
        input.onchange = e => {
            const file = e.target.files[0];
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const bundle = JSON.parse(fileReader.result);
                if (bundle.hash !== this.props.runData.hashsum) {
                    if (!confirm("The project file was created for a different bundle. Are you sure want to continue? You can easily break everything if you don't know what are you doing!")) {
                        return;
                    }
                }
                this.props.onProjectDataChange({
                    ...this.props.projectData,
                    ...bundle.data,
                });
            };
            fileReader.readAsText(file);
        }
        input.click();
    };

    _exportProject = () => {
        const jsonText = JSON.stringify({"data": this.props.projectData, "hash": this.props.runData.hashsum});
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonText));
        element.setAttribute('download', "rdtest-project.json");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    _exportData = () => {
        const rows = [];
        for (const key of Object.keys(this.props.projectData)) {
            const {markers, alias} = this.props.projectData[key];
            for (const marker of markers) {
                rows.push(`${key}\t${alias}\t${marker.label}\t${marker.ts}\t${marker.distance}\t${marker.position.lat}\t${marker.position.lon}`);
            }
        }
        const result = rows.join("\n");
        navigator.clipboard.writeText(result).then(function() {
            alert('Copied to clipboard');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    _renderOption = (key) => {
        const alias = this.props.projectData[key].alias;
        let cls = "run-selector-item";
        if (this.props.activeKey === key) {
            cls += " active"
        }
        return (
            <div className={ cls } onClick={ () => this.props.onRunSelect(key) } key={ key }>
                { alias }
            </div>
        );
    }

    render() {
        let options = this.props.runData.getAllKeys(this.props.projectData);
        return (
            <div className="left-menu">
                <div className="run-selector">
                    { options.map(this._renderOption) }
                </div>
                <div className="controls">
                    <div className="control-button" onClick={ this._importProject }>
                        Import project
                    </div>
                    <div className="control-button" onClick={ this._exportProject }>
                        Export project
                    </div>
                    <div className="control-button" onClick={ this._exportData }>
                        Export all markers
                    </div>
                </div>
            </div>
        );
    }
}
