import React from "react";

import RunEditor from "run_editor";
import RunSelector from "run_selector";

export default class Workbench extends React.Component {
    state = {
        "activeKey": null,
    }

    render() {
        return (
            <div className="workbench">
                <RunSelector
                    activeKey={ this.state.activeKey }
                    runData={ this.props.runData }
                    projectData={ this.props.projectData }
                    onRunSelect={ activeKey => this.setState({activeKey}) }
                    onProjectDataChange={ this.props.onProjectDataChange }
                />
                <RunEditor
                    key={ this.state.activeKey }
                    activeKey={ this.state.activeKey }
                    runData={ this.props.runData }
                    projectData={ this.props.projectData }
                    onProjectDataChange={ this.props.onProjectDataChange }
                />
            </div>
        );
    }
}