import React from "react";
import {sprintf} from "sprintf-js";

export default class EditorMarker extends React.Component {
    state = {
        showInfo: false,
        showMenu: false,
    }

    _handleMenuClose = (e) => {
        e.stopPropagation();
        this.setState({showMenu: false, showInfo: false});
    }

    _handleRename = () => {
        const newName = window.prompt("Please enter the new name", this.props.label);
        if (newName == null) {
            return;
        }
        this.props.onChange({ label: newName });
    }

    _handleDelete = () => {
        if (!confirm(`Are you sure want to delete marker ${this.props.label}}`)) {
            return;
        }
        this.props.onDelete();
    }

    renderInfo() {
        if (!this.state.showInfo) {
            return null;
        }
        const distanceFt = Math.round(this.props.distance * 3.28084);
        const date = new Date(this.props.ts);
        const time = sprintf("%02d:%02d:%02d.%02d", date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), Math.round(date.getUTCMilliseconds() / 10));
        return (
            <div className="marker-info">
                <b>{ this.props.label }</b><br />
                <b>Time: </b>{ `${time}` }<br />
                <b>Distance: </b>{ `${distanceFt} ft` }
            </div>
        );
    }

    renderMenu() {
        if (!this.state.showMenu) {
            return null;
        }
        return (
            <div className="marker-menu" onMouseLeave={ this._handleMenuClose }>
                <div className="menu-item" onClick={ this._handleRename }>
                    Rename
                </div>
                <div className="menu-item" onClick={ this._handleDelete }>
                    Delete
                </div>
                <div className="menu-item" onClick={ this._handleMenuClose }>
                    Close this menu
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                className="marker-icon"
                style={{"left": `${this.props.offsetPx}px`}}
                onMouseOver={ () => this.setState({showInfo: true}) }
                onMouseOut={ () => this.setState({showInfo: false}) }
                onClick={ () => this.setState({showMenu: !this.state.showMenu}) }
            >
                { this.renderInfo() }
                { this.renderMenu() }
            </div>
        )
    }
}
