import React from "react";
import { render } from "react-dom";

import RunDataFile from "run_data_file";
import Workbench from "workbench";
import FirstPage from "first_page";

class EditingUI extends React.Component {
    state = {
        "runData": null,
        "projectData": null,
    }

    _handleFileLoaded = (hashsum, data) => {
        let rdFile = new RunDataFile(hashsum, data);
        this.setState(rdFile.getInitialState());
    }

    _handleProjectDataChange = (projectData) => {
        this.setState({projectData});
        window.localStorage.setItem(
            `workset_${this.state.runData.hashsum}`,
            JSON.stringify(projectData),
        );
    }

    render() {
        if (this.state.runData == null) {
            return (
                <FirstPage
                    onLoad={ this._handleFileLoaded }
                />
            );
        }
        return (
            <Workbench
                runData={ this.state.runData }
                projectData={ this.state.projectData }
                onProjectDataChange={ this._handleProjectDataChange }
            />
        )
    }
}

render(
    <EditingUI />,
    document.getElementById("content")
);
