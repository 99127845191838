import React from "react";
import {sprintf} from "sprintf-js";

export default class TimeSelectorOverlay extends React.Component {
    state = {
        offsetPx: null,
        menuInfo: null,
    }

    _handleMouseMove = (e) => {
        if (this._component == null) {
            return
        }
        const absMouseX = e.clientX;
        const absElementX = this._component.getBoundingClientRect().left;
        const mouseX = absMouseX - absElementX;
        if (mouseX < 0) {
            return;
        }
        this.setState({ offsetPx: mouseX });
    }

    _handleClick = (e) => {
        const absMouseX = e.clientX;
        const absElementX = this._component.getBoundingClientRect().left;
        const offsetPx = absMouseX - absElementX;
        const {m, runData, activeKey} = this.props;
        const ts = m.timeStart + offsetPx * m.pxSize;
        const [distance, position] = runData.getDistanceAndPositionFromTime(activeKey, ts);
        this.setState({menuInfo: {ts, distance, position, offsetPx}});
    }

    _handleNewMarker = (e) => {
        e.stopPropagation();
        const newName = window.prompt("Please enter the new name", this.props.label);
        if (newName == null) {
            return;
        }
        const {ts, position, distance} = this.state.menuInfo;
        this.props.onCreate({
            ts, position, distance,
            label: newName
        });
        this.setState({ menuInfo: null });
    }

    _handleMove = (e, idx) => {
        e.stopPropagation();
        const {ts, position, distance} = this.state.menuInfo;
        this.props.onChange(idx, {
            ts, position, distance,
        });
        this.setState({ menuInfo: null });
    }

    renderMenu() {
        const {menuInfo} = this.state;
        if (menuInfo == null) {
            return null;
        }
        return (
            <div className="overlay-menu" style={{ left: 5 + menuInfo.offsetPx}} onMouseLeave={ () => this.setState({menuInfo: null}) }>
                <div className="menu-item" onClick={ this._handleNewMarker }>
                    Put a new marker here
                </div>
                { this.props.markers.map((marker, idx) =>
                    <div className="menu-item" onClick={ (e) => this._handleMove(e, idx) } key={ idx }>
                        Move {marker.label} here
                    </div>
                ) }
                <div className="menu-item" onClick={ (e) => { e.stopPropagation(), this.setState({menuInfo: null}); } }>
                    Close this menu
                </div>
            </div>
        );
    }

    renderHint() {
        const {offsetPx} = this.state;
        if (offsetPx == null) {
            return null;
        }
        const {m, runData, activeKey} = this.props;
        const ts = m.timeStart + offsetPx * m.pxSize;
        const date = new Date(ts);
        const time = sprintf("%02d:%02d:%02d.%02d", date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), Math.round(date.getUTCMilliseconds() / 10));
        const [distanceM, _position] = runData.getDistanceAndPositionFromTime(activeKey, ts);
        const distanceFt = distanceM * 3.28084
        return (
            <div className="overlay-hint" style={{ left: `${offsetPx}px` }}>
                <b>Time:&nbsp;</b>{time}<br />
                <b>Distance:&nbsp;</b>{`${Math.round(distanceFt)} ft`}
            </div>
        );
    }

    renderPointer() {
        const {offsetPx} = this.state;
        if (offsetPx == null) {
            return null;
        }
        return (
            <div
                className="overlay-pointer"
                style={{ left: `${offsetPx}px` }}
            />
        );
    }

    render() {
        return (
            <div
                className="overlay"
                onMouseLeave={ () => this.setState({offsetPx: null}) }
                onMouseMove={ this._handleMouseMove }
                onClick={ this._handleClick }
                ref={ (r) => this._component = r }
            >
                { this.renderPointer() }
                { this.renderHint() }
                { this.renderMenu() }
            </div>
        );
    }
}