import React from "react";

import FileOpener from "file_opener";

import pako from "pako";
import strftime from "strftime";

export default class FirstPage extends React.Component {
    state = {
        "loading": false
    }

    _handleFilesMerge = (contents) => {
        const result = {};
        for (let content of contents) {
            for (let prevKey of Object.keys(content)) {
                let nextKey = prevKey;
                let suffix = "";
                let cnt = 1;
                while (result.hasOwnProperty(nextKey)) {
                    suffix = ` (${cnt++})`;
                    nextKey = `${prevKey}${suffix}`;
                }
                result[nextKey] = content[prevKey];
            }
        }
        const jsonText = JSON.stringify(result);
        const gzData = pako.gzip(jsonText);
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(new Blob([gzData])));
        element.setAttribute('download', strftime("%Y-%m-%d_%H-%M-%S-rdtest-merge.rtbundle"));
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.setState({loading: false});
    }

    _handleFileSelected = () => {
        this.setState({loading: true});
    }

    renderWaitScreen() {
        if (!this.state.loading) {
            return null;
        }
        return (
            <div className="loading-screen">
                Please wait...
            </div>
        );
    }

    render() {
        return (
            <div className="first-page">
                { this.renderWaitScreen() }
                <div className="first-page-content">
                    <h1>RD test workspace</h1>
                    <div className="first-page-body">
                        <div className="section">
                            <p>
                                This is a tool for post-processing data obtained from running
                                the <b>RDTest</b> application.
                            </p>
                        </div>
                        <div className="section">
                            <h2>Load a data file</h2>
                            <p>
                                If you have only one file to work with or already merged
                                multiple files (see below), please select your file here.
                            </p>
                            <FileOpener
                                onSelected={ this._handleFileSelected }
                                onLoad={ this.props.onLoad }
                            />
                        </div>
                        <div className="section">
                            <h2>Merge multiple data files</h2>
                            <p>
                                If you have recorded the test from multiple phones
                                (e.g. one at the radar car, another at the RD car),
                                it is better to merge the data files first.
                            </p>
                            <p>
                                Select all files at once. Once you complete the selection
                                a merged bundle will immediately download.
                            </p>
                            <FileOpener
                                allowMultiple
                                onSelected={ this._handleFileSelected }
                                onLoad={ this._handleFilesMerge }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}