import pako from "pako";
import sha1 from "js-sha1";

import React from "react";

export default class FileOpener extends React.Component {
    _handleFilesRead(contents) {
        const results = [];
        for (const gzData of contents) {
            const jsonData = pako.inflate(gzData, {to: 'string'});
            const rawData = JSON.parse(jsonData);
            if (!this.props.allowMultiple) {
                const hash = sha1(gzData);
                this.props.onLoad(hash, rawData);
                return;
            }
            results.push(rawData);
        }
        this.props.onLoad(results);
    };
    _handleFileChosen = (e) => {
        this.props.onSelected();
        e.persist();
        const contents = [];
        for (const file of e.target.files) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                contents.push(fileReader.result);
                if (contents.length === e.target.files.length) {
                    this._handleFilesRead(contents);
                }
            };
            fileReader.readAsArrayBuffer(file);
        }
    };
    render() {
        return (
            <form>
                <input
                    type="file"
                    accept=".rtbundle"
                    multiple={ this.props.allowMultiple }
                    onChange={ this._handleFileChosen }
                />
            </form>
        )
    }
}